<template>
  <div :class="[`container-${this.isLoadingContent ? 'loading' : 'rendered'}`, sceneViewCompositionMode ? 'sceneView-Composition-Mode' : '']">
    <loading-state v-if="this.isLoadingContent" />
    <div v-else-if="this.globalError" class="notification-banner-container">
      <notification-banner
      theme='error'
      title='Content Unavailable'
      :alternate="true"
      :message="this.globalError"
      :show-close-button="false" />
    </div>
    <div v-else class="viewer-error-container">
      <notification-banner v-if="this.showViewerErrorModal"
        theme='error'
        title='Something went wrong'
        :alternate="true"
        :message="this.error.message"
        :show-close-button="true"
        class='position-fixed fixed-top'
        @close-banner="errorModalVisible = false" />
      <div class="viewer-container flex-container">
        <div :class="[isSplitView ? 'd-flex flex-column' : '', layerPanelActive ? 'layer-panel-height' : 'collapsed-layer-panel-height']">
          <layers-panel
            v-show="viewerMode == 'Viewer' || sceneViewCompositionMode"
            ref="primaryLayerPanel"
            :viewId="1"
            :class="isSplitView ? 'h-50' : ''"
            :simulation="simulation"
            :geometryAssets="geometryAssets"
            :geometryLayerSets="geometryLayerSets"
            :showByDefaultLayerSets="showByDefaultLayerSets"
            :savedLayers="savedLayers"
            :allLayerSets="allLayerSets"
            :selectedLayerSets="selectedLayerSets"
            :defaultAssets="defaultAssets"
            :active='layerPanelActive'
            :layerSetsMatchingGlobalFilter='layerSetsMatchingGlobalFilter'
            :assetsMatchingGlobalFilter='assetsMatchingGlobalFilter'
            :globalFilter='globalFilter'
            :layerPickerInputs='layerPickerInputs'
            :appliedFilterList='appliedFilterList'
            :multipleScreenshots='multipleScreenshots'
            rank='primary'
            @resultLayersPresent="handleResultLayersList"
            @geometryLayersPresent="handleGeometryLayersList"
            @add-scenario="openCreateScenarioModal(selectedProject.id)"
            @toggleLayers="toggleLayers"
            @selectSimulation="selectSimulation"
            @selectedSimulationLabel="selectedSimulationLabel"
            @saveLayers="saveLayers"
            @enableProbeMode="enableProbeMode"
            @takeMultipleScreenshots="takeMultipleScreenshots"
            @toggleLayerPanel="toggleLeftPanel"
            @setFilterValue="setFilterValue"
            @clearGlobalFilter="clearGlobalFilter"
            @setGraphRequiresProbes="setGraphRequiresProbes"
            @clearProbes="clearProbePoints"
            @toggleWireframe="toggleWireframe"
            @saveInsight="saveInsight"
            @deleteInsight="deleteInsight"
            @saveInsightOrder="saveInsightOrder"
            @removeAnnotationFromViewer="removeAnnotationFromViewer"
          />
          <insights-panel
            ref="insightsPanel"
            v-show="viewerMode == 'Insights'"
            @insightLayersPresent="handleInsightLayersPresent"
            @removingAllLayers="removingAllLayers"
            @toggleInsightsPanel="toggleLeftPanel"
            @removeAnnotationFromViewer="removeAnnotationFromViewer"
            @removeAnnotationTypeFromViewerAndCleanUpAnnotations="removeAnnotationTypeFromViewerAndCleanUpAnnotations"
            :active='layerPanelActive'
            :geometryLayerSets="geometryLayerSets" />
          <layers-panel
            v-if='isSplitView'
            class='h-50'
            :viewId="2"
            :simulation="secondaryViewerSimulation"
            :geometryAssets="secondaryViewerGeometryAssets"
            :geometryLayerSets="secondaryViewerGeometryLayerSets"
            :showByDefaultLayerSets="secondaryViewerShowByDefaultLayerSets"
            :savedLayers="secondaryViewerSavedLayers"
            :allLayerSets="allSecondaryViewerLayerSets"
            :selectedLayerSets="secondaryViewerSelectedLayerSets"
            :defaultAssets="secondaryViewerDefaultAssets"
            :active='layerPanelActive'
            :layerSetsMatchingGlobalFilter='secondaryViewerLayerSetsMatchingGlobalFilter'
            :assetsMatchingGlobalFilter='secondaryViewerAssetsMatchingGlobalFilter'
            :globalFilter='secondaryViewerGlobalFilter'
            :layerPickerInputs='secondaryViewerLayerPickerInputs'
            :appliedFilterList='secondaryViewerAppliedFilterList'
            :multipleScreenshots='secondaryMultipleScreenshots'
            rank='secondary'
            @resultLayersPresent="secondaryHandleResultLayersList"
            @geometryLayersPresent="secondaryHandleGeometryLayersList"
            @add-scenario="openCreateScenarioModal(selectedProject.id)"
            @toggleLayers="secondaryViewerToggleLayers"
            @selectSimulation="selectSecondaryViewerSimulation"
            @saveLayers="saveSecondaryViewerLayers"
            @enableProbeMode="secondaryViewerEnableProbeMode"
            @takeMultipleScreenshots="secondaryTakeMultipleScreenshots"
            @toggleLayerPanel="toggleLeftPanel"
            @setFilterValue="setSecondaryViewerFilterValue"
            @clearGlobalFilter="clearSecondaryViewerGlobalFilter"
            @setGraphRequiresProbes="setSecondaryViewerGraphRequiresProbes"
            @clearProbes="secondaryViewerClearProbePoints"
            @toggleWireframe="secondaryViewerToggleWireframe"
          />
        </div>
        <viewer ref='primaryViewer'
          v-show="showPrimaryViewer"
          :simulation="simulation"
          :resultLayersPresent="this.resultLayersList"
          :geometryLayersPresent="geometryLayersList"
          :insightLayersPresent="this.insightLayersList"
          :selectedSimulationId="selectedSimulationId"
          :layersToToggle="layersToToggle"
          :allWindroses="windroses"
          :layerPanelActive="layerPanelActive"
          :layerIsLoading='layerIsLoading'
          :selectedColorTheme="selectedColorTheme"
          :showProbeMode="showDataProbeToggleButton"
          :probeGroup="probeGroup"
          :graphRequiresProbes="graphRequiresProbes"
          :graphAssets="graphAssets"
          :wireframeOn="wireframeOn"
          :geometryLayerSets="geometryLayerSets"
          :removeAllLayers="removeAllLayers"
          :simulationLabel="this.simulationLabel"
          @takeMultipleScreenshots="takeMultipleScreenshots"
          @removingAllLayers="removingAllLayers"
          @setLayerIsLoading='setLayerIsLoading'
          @selectTheme="selectTheme"
          @graphProbePlaced="graphProbePlaced"
          rank='primary'
          @saveSceneView="saveSceneViewDetails"
          @createNewInsight="createNewInsight"
          @saveSceneForNewInsight="saveSceneForNewInsight"
        />
        <data-panel
          v-if="showDataPanel"
          :graphProbes="graphProbes"
          :graphAssets="listOfGraphAssets"
          @setGraphRequiresProbes="setGraphRequiresProbes"
          @graphProbeTypeChanged="graphProbeTypeChanged"
          @clearProbes="clearProbePoints"
          @removeProbe="removeProbe"/>
        <split-view-divider v-if='isSplitView' />
        <viewer ref='secondaryViewer'
          v-if='isSplitView'
          :resultLayersPresent="secondaryResultLayersList"
          :geometryLayersPresent="secondaryGeometryLayersList"
          :insightLayersPresent="insightLayersList"
          :simulation="secondaryViewerSimulation"
          :selectedSimulationId="selectedSecondaryViewerSimulationId"
          :layersToToggle="secondaryViewerLayersToToggle"
          :allWindroses="secondaryViewerWindroses"
          :layerPanelActive="layerPanelActive"
          :layerIsLoading='secondaryViewerLayerIsLoading'
          :selectedColorTheme="selectedSecondaryViewerColorTheme"
          :showProbeMode="secondaryViewerShowDataProbeToggleButton"
          :probeGroup="secondaryViewerProbeGroup"
          :graphRequiresProbes="secondaryViewerGraphRequiresProbes"
          :graphAssets="secondaryViewerGraphAssets"
          :wireframeOn="secondaryViewerWireframeOn"
          :geometryLayerSets="secondaryViewerGeometryLayerSets"
          @takeMultipleScreenshots="secondaryTakeMultipleScreenshots"
          @setLayerIsLoading='setSecondaryViewerLayerIsLoading'
          @selectTheme="selectSecondaryViewerTheme"
          @graphProbePlaced="secondaryViewerGraphProbePlaced"
          rank='secondary'
        />
        <content-not-found-page 
          v-if="showContentNotFound"
          :contentType="notFoundContentType"/>
        <image-container 
          v-show="(viewerMode == 'Insights' && currentInsightIsImageView && !createInsightMode && !editInsightMode) || viewerMode == 'Images'"
          @createNewInsight="createNewInsight"
          @saveImageViewForNewInsight="saveImageViewForNewInsight"/>
        <report-container 
          v-show="(viewerMode == 'Insights' && currentInsightIsReportView && !createInsightMode && !editInsightMode) || viewerMode == 'Reports'" 
          :setting="viewerMode == 'Insights' ? 'insights' : 'reports'"/>
        <insight-type-selection-panel 
          ref="insightSelectionPanel"
          v-if="displaysInsightSelection"
          @createInsight = "createInsightButtonClicked"
          />
        <create-scenario-modal :show-modal="createScenarioModalIsVisible" :project-id="scenarioProjectId" @close-modal="closeCreateScenarioModal" />
        <create-scenario-success-modal :show-modal="createScenarioSuccessModalIsVisible" @close-modal="closeCreateScenarioSuccessModal" />
      </div>
    </div>
  </div>
</template>

<script>
import Viewer from './viewer/Viewer';
import LayersPanel from './viewer/LayersPanel';
import SplitViewDivider from './viewer/SplitViewDivider.vue';
import LoadingState from '@/components/views/LoadingState';
import CreateScenarioModal from '@/components/projects/list/CreateScenarioModal';
import CreateScenarioSuccessModal from '@/components/projects/list/CreateScenarioSuccessModal';
import { AUTHOR_INSIGHTS } from '@/constants/permissions';
import { EventBus } from '@/network/eventbus';
import { NotificationBanner } from 'rwdi-widgets';
import { mapGetters, mapActions } from 'vuex';
import { GET_COMPANY } from '@/store/actions/permissions';
import ReportContainer from './ReportContainer';
import ImageContainer from './ImageContainer';
import InsightTypeSelectionPanel from './viewer/InsightTypeSelectionPanel';
import _ from 'lodash';
import projectApiService from '@/network/project';
import {GET_COMPANY_SUBSCRIPTIONS} from '@/store/actions/permissions';
import InsightsPanel from './insights/InsightsPanel';
import ContentNotFoundPage from './viewer/ContentNotFoundPage';
import DataPanel from './viewer/DataPanel';

export default {
  name: 'ViewerContainer',
  components: {
    Viewer,
    LayersPanel,
    InsightsPanel,
    DataPanel,
    LoadingState,
    CreateScenarioModal,
    CreateScenarioSuccessModal,
    NotificationBanner,
    ReportContainer,
    ImageContainer,
    SplitViewDivider,
    InsightTypeSelectionPanel,
    ContentNotFoundPage
  },
  mounted() {
    this.$root.$on('toggleWireframeInsight', (layerIdentifier) => {
      this.toggleWireframe(layerIdentifier);
    });

    this.$root.$on('saveInsight', (currentInsight, exitEditInsightMode) => {
      this.saveInsight(currentInsight, exitEditInsightMode);
    });
  },
  async created() {
    await this.$store.dispatch(GET_COMPANY_SUBSCRIPTIONS);
  },
  pageTitle() {
    return `${this.$store.getters['project/name'] || 'Loading...'}`;
  },
  computed: {
    listOfGraphAssets() {
      return [this.graphAssets];
    },
    showDataPanel() {
      return this.viewerMode === 'Viewer' && this.graphAssets.length && !this.isSplitView;
    },
    loggedInUserIsOTPUser() {
      return this.loggedInUser?.is_otp_user;
    },
    shareContentNotFound() {
      const contentType = this.notFoundContentType;
      let contentFound;
      if(contentType === 'simulation results') {
        contentFound = this.selectedProject?.studies?.at(0).simulation_labels.find(label => label.category === 'RESULT');
        return !contentFound;
      } else if(contentType === 'insights') {
        contentFound = this.currentInsights.find(insight => insight.insight_status === 'Published');
        return !contentFound;
      }
      return null;
    },
    notFoundContentType() {
      let projectShare = this.userProjectShares && this.userProjectShares.length > 0 ? this.userProjectShares[0] : null;
      if(projectShare) {
        switch(projectShare.share_content) {
        case 'INSIGHTS':
          return 'insights';
        case 'PROJECT':
          return 'simulation results';
        }
      }
      return null;
    },
    showPrimaryViewer() {
      return (this.viewerMode == 'Viewer' || (this.viewerMode == 'Insights' && this.currentInsightIsSceneView && !this.createInsightMode)) && !this.showContentNotFound;
    },
    showContentNotFound() {
      return this.loggedInUserIsOTPUser && this.shareContentNotFound;
    },
    userCanAuthorInsights() {
      return (this.$store.getters['allowedPermissions'] || {})[AUTHOR_INSIGHTS];
    },
    isSplitView() {
      return !!this.$route.query?.secondary;
    },
    currentInsights() {
      if (!this.selectedStudy) {
        return [];
      }
      return this.selectedStudy.insights;
    },
    currentInsightIsSceneView() {
      return this.currentInsight?.insight_type == 'Scene';
    },
    currentInsightIsImageView() {
      return this.currentInsight?.insight_type == 'Image';
    },
    currentInsightIsReportView() {
      return this.currentInsight?.insight_type == 'Report';
    },
    isLoadingContent() {
      return this.projectIsLoading || this.isLoading;
    },
    showViewerErrorModal() {
      return this.error != null && this.errorModalVisible;
    },
    error() {
      //return this.projectError || this.simulation?.errors[0] || undefined;
      if (this.projectError) {
        return this.projectError;
      } else if (this.simulation?.errors && this.simulation.errors.length > 0) {
        if (typeof this.simulation?.errors[0] === 'string') {
          return {message: this.simulation?.errors[0]};
        } else {
          return this.simulation?.errors[0];
        }
      } else {
        return undefined;
      }

    },
    createScenarioSuccessModalIsVisible() {
      return this.$store.getters['project/createScenarioSuccessModalVisible'];
    },
    createdScenarioInformation() {
      return this.$store.getters['project/createdScenarioInformation'];
    },
    hasInsights() {
      let selectedStudy = this.$store.getters['project/selectedStudy'];
      return selectedStudy?.insights.length > 0;
    },
    displaysInsightSelection() {
      if (this.viewerMode== 'Insights') {
        if (this.createInsightMode) {
          return true;
        } else if (this.editInsightMode) {
          if (this.currentInsightIsImageView || this.currentInsightIsReportView) {
            return true;
          }
        }
      } 

      return false;
    },
    ...mapGetters('project', ['sceneViewCompositionMode', 'selectedStudy', 'selectedProject', 'projectIsLoading', {projectError: 'error'}, 'createdScenarioFormValues', 'createdScenarioId', 'viewerMode', 'currentInsight', 'currentImage', 'createInsightMode', 'editInsightMode', 'annotationTypeToCreate']),
    ...mapGetters('project/simulationAsset', ['globalFilter', 'secondaryViewerGlobalFilter','geometryLayerSets', 'geometryAssets', 'secondaryViewerGeometryLayerSets', 'secondaryViewerGeometryAssets',
      'showByDefaultLayerSets', 'secondaryViewerShowByDefaultLayerSets', 'simulationIsLoading', 'simulation', 'secondaryViewerSimulation', 'windroses', 'secondaryViewerWindroses', 'selectedSimulationId',
      'selectedSecondaryViewerSimulationId', 'allLayerSets', 'allSecondaryViewerLayerSets', 'selectedLayerSets', 'secondaryViewerSelectedLayerSets',
      'defaultAssets', 'secondaryViewerDefaultAssets', 'layerSetsMatchingGlobalFilter', 'secondaryViewerLayerSetsMatchingGlobalFilter',
      'assetsMatchingGlobalFilter', 'secondaryViewerAssetsMatchingGlobalFilter', 'layerIsLoading', 'secondaryViewerLayerIsLoading',
      'layerPickerInputs', 'secondaryViewerLayerPickerInputs', 'appliedFilterList', 'secondaryViewerAppliedFilterList', 'graphAssets']),
    ...mapGetters('project/viewer', ['layersToToggle', 'secondaryViewerLayersToToggle', 'savedLayers', 'secondaryViewerSavedLayers',
      'selectedColorTheme', 'selectedSecondaryViewerColorTheme', 'probeGroup', 'secondaryViewerProbeGroup', 'graphRequiresProbes', 'secondaryViewerGraphRequiresProbes']),
    ...mapGetters({ globalError: 'error' }),
    ...mapGetters(['loggedInUser', 'userCompany', 'userProjectShares'])
  },
  methods: {
    handleInsightLayersPresent(insightLayersPresent){
      this.insightLayersList = insightLayersPresent;
    },
    handleResultLayersList(resultLayersPresent){
      this.resultLayersList = resultLayersPresent;
    },
    handleGeometryLayersList(geometryLayersPresent){
      this.geometryLayersList = geometryLayersPresent;
    },
    secondaryHandleResultLayersList(resultLayersPresent){
      this.secondaryResultLayersList = resultLayersPresent;
    },
    secondaryHandleGeometryLayersList(geometryLayersPresent){
      this.secondaryGeometryLayersList = geometryLayersPresent;
    },
    selectedSimulationLabel(label){
      this.simulationLabel = label;      
    },
    async deleteInsight(insightToDelete) {
      await this.$store.dispatch('project/deleteInsight', {
        projectId: Number(this.$route.params.id),
        studyId: Number(this.$route.params.study),
        insightId: insightToDelete.id
      });
      
      if (insightToDelete.pins) {
        for (let pin of insightToDelete.pins) {
          this.$refs.primaryViewer.removeAnnotationPin(pin);
        }
      }
      
      this.$root.$emit('goToPreviousInsightIfExists');
    },
    async saveSceneViewDetails(currentSceneViewDetails) {
      if(this.currentInsight.sceneview_set.length > 0) {
        await this.$store.dispatch('project/deleteSceneView', {
          projectId: Number(this.$route.params.id),
          studyId: Number(this.$route.params.study),
          insightId: this.currentInsight.id,
          sceneViewId: this.currentInsight.sceneview_set[0].id
        });
      }
      currentSceneViewDetails.title = this.currentInsight.title;
      await this.$store.dispatch('project/addSceneView', {
        projectId: Number(this.$route.params.id),
        studyId: Number(this.$route.params.study),
        insightId: this.currentInsight.id,
        insightDetails: currentSceneViewDetails,
      });
      this.$root.$emit('goToLastInsightIfIndexTooLarge');

      this.$store.dispatch('project/setSavingSceneViewInsight', false);
      this.$store.dispatch('project/setSceneViewCompositionMode', false);
    },
    async saveImageViewForNewInsight(done) {
      const currentImageViewDetails = {
        title: this.currentInsight.title,
        insight_id: this.currentInsight.id,
        study_image_id: this.currentImage.id
      };

      let currentViewSet = await this.$store.dispatch('project/addImageView', {
        projectId: Number(this.$route.params.id),
        studyId: Number(this.$route.params.study),
        insightId: this.currentInsight.id,
        insightDetails: currentImageViewDetails,
      });
      this.currentInsight.imageview_set[0] = currentViewSet;
      this.$root.$emit('goToLastInsight');
      await this.$nextTick();
      done();
    },
    async saveSceneForNewInsight({ details, done }) {
      details.title = this.currentInsight.title;
      await this.$store.dispatch('project/addSceneView', {
        projectId: Number(this.$route.params.id),
        studyId: Number(this.$route.params.study),
        insightId: this.currentInsight.id,
        insightDetails: details,
      });
      this.$root.$emit('goToLastInsight');
      await this.$nextTick();
      done();
    },
    async createInsightButtonClicked() {
      //tell the insight panel to save the current insight
      await this.saveInsight();

      this.$root.$emit('goToLastInsight');

      let insightType = this.$store.getters['project/insightTypeSelected'];
      if (insightType == 'Scene') {
        this.$refs.insightSelectionPanel.sceneInsightCreated();
      }

      //turn off create mode for insight
      this.$root.$emit('editInsight');
    },
    async createNewInsight(done) {
      if(this.viewerMode === 'Viewer') {
        this.$store.dispatch('project/setInsightTypeSelected', 'Scene');
      } else if (this.viewerMode === 'Images') {
        this.$store.dispatch('project/setInsightTypeSelected', 'Image');
      }
      
      await this.saveInsight(null);
      this.$root.$emit('goToLastInsight');
      await this.$nextTick();
      done();
    },
    async saveInsight(currentInsight, exitEditInsightMode=false) {
      // Save Insight
      if (!currentInsight) {
        let insightName = this.$store.getters['project/newInsightTitle'];
        let insightDesc = this.$store.getters['project/newInsightDesc'];
        let insightType = this.$store.getters['project/insightTypeSelected'];
        let insightStatus = this.$store.getters['project/newInsightStatus'];
        const study = this.$store.getters['project/studies']?.find(study => study.id === Number(this.$route.params.study));
        
        if (!insightName) {
          insightName = 'new insight';
        }
        if (!insightDesc) {
          insightDesc = 'sample description';
        }
        let sort_order = 0;
        if (this.currentInsights.length > 0) {
          sort_order = Math.max.apply(Math, this.currentInsights.map(x => x.sort_order)) + 1;
        }
        
        const currentInsightDetails = {
          title: insightName,
          study_id: Number(this.$route.params.study),
          description: insightDesc,
          service_lines: study.service_lines,
          insight_type: insightType,
          insight_status: insightStatus,
          pins: [],
          sort_order: sort_order
        };

        await this.$store.dispatch('project/addInsight', {
          projectId: Number(this.$route.params.id),
          studyId: Number(this.$route.params.study),
          insightDetails: currentInsightDetails,
        });
      } else {
        //update existing insight
        if (currentInsight.insight_type != 'Scene') {
          await this.$refs.insightSelectionPanel.saveBtnClicked();
        }

        try {
          await projectApiService.updateInsight(Number(this.$route.params.id), Number(this.$route.params.study), currentInsight);
          if(exitEditInsightMode){          
            EventBus.$emit('TOAST', {
              variant: 'success',
              content: 'Insight saved successfully.'
            });
          }
          if (currentInsight.insight_type == 'Scene') {
            if(exitEditInsightMode)
              this.$store.dispatch('project/setEditInsightMode', false);               
            this.$store.dispatch('project/setCreateInsightMode', false);
          }
          this.$root.$emit('finishedUpdatingInsight', this.currentInsight, true);
        } catch (ex) {
          EventBus.$emit('TOAST', {
            variant: 'danger',
            content: 'Failed to save insight.  Please try again.'
          });
        }
      }
      // reset insight status after insight save/update
      this.$store.dispatch('project/setNewInsightStatus', 'Draft');
    },
    async saveInsightOrder() {
      let saveCalls = [];
      try {
        for (let insight of this.currentInsights) {
          let saveCall = projectApiService.updateInsight(Number(this.$route.params.id), Number(this.$route.params.study), insight);
          saveCalls.push(saveCall);
        } 
        await Promise.all(saveCalls);
        EventBus.$emit('TOAST', {
          variant: 'success',
          content: 'Insight sort order saved.'
        });
      } catch (ex) {
        EventBus.$emit('TOAST', {
          variant: 'danger',
          content: 'Failed to save insight order.  Please try again.'
        });
      }
    },
    removeAnnotationFromViewer(annotation) {
      this.$refs.primaryViewer.removeAnnotationPin(annotation);
    },
    removeAnnotationTypeFromViewerAndCleanUpAnnotations(annotationTypeId) {
      for (const pin of this.currentInsight.pins) {
        if (pin.insight_pin_type_id === annotationTypeId)
          this.$refs.primaryViewer.removeAnnotationPin(pin);
      }

      this.$store.dispatch('project/clearPinsWithPinTypeId', {
        projectId: Number(this.$route.params.id),
        studyId: Number(this.$route.params.study),
        insightId: this.currentInsight.id,
        annotationTypeId: annotationTypeId
      });
    },
    enableProbeMode(showProbeMode){
      this.showDataProbeToggleButton = showProbeMode;
    },
    secondaryViewerEnableProbeMode(showProbeMode) {
      this.secondaryViewerShowDataProbeToggleButton = showProbeMode;
    },
    graphProbePlaced(probe) {
      this.graphProbes.push(probe);
    },
    setGraphRequiresProbes(value) {
      this.setGraphRequiresProbesStore(value);
    },
    setSecondaryViewerGraphRequiresProbes(value) {
      this.setSecondaryViewerGraphRequiresProbesStore(value);
    },
    graphProbeTypeChanged(value) {
      this.setProbeGroup(value);
    },
    clearProbePoints() {
      this.graphProbes.length = 0;
      this.$refs.primaryViewer.clearGraphProbes();
    },
    removeProbe(probeToRemove) {
      this.$refs.primaryViewer.removeProbe(probeToRemove.uuid);
      this.graphProbes.splice(this.graphProbes.indexOf(probeToRemove), 1);
    },
    toggleWireframe(layerIdentifier) {
      this.wireframeOn[layerIdentifier] = !this.wireframeOn[layerIdentifier];
      this.wireframeOn = _.clone(this.wireframeOn);
    },
    secondaryViewerToggleWireframe(layerIdentifier) {
      this.secondaryViewerWireframeOn[layerIdentifier] = !this.secondaryViewerWireframeOn[layerIdentifier];
      this.secondaryViewerWireframeOn = _.clone(this.secondaryViewerWireframeOn);
    },
    openCreateScenarioModal(projectId) {
      this.createScenarioModalIsVisible = true;
      this.scenarioProjectId = projectId;
    },
    closeCreateScenarioModal() {
      this.createScenarioModalIsVisible = false;
      this.scenarioProjectId = null;
      if (this.createdScenarioInformation != null) {
        this.$router.replace({
          name: 'ViewerContainer',
          params: {
            id: this.createdScenarioInformation.projectId,
            study: this.createdScenarioInformation.studyId,
            configuration: this.createdScenarioInformation.configurationId
          }
        });
        window.location.reload();
      }
    },
    closeCreateScenarioSuccessModal() {
      this.$store.dispatch('project/setCreateScenarioSuccessModalVisible', false);
    },
    toggleLeftPanel() {
      this.layerPanelActive = !this.layerPanelActive;
      this.$store.dispatch('project/setLayersPanelOpen', this.layerPanelActive);
    },
    takeMultipleScreenshots(status){
      this.multipleScreenshots = status;
    },
    secondaryTakeMultipleScreenshots(status){
      this.secondaryMultipleScreenshots = status;
    },
    removingAllLayers(status){
      this.removeAllLayers = status;
    },
    ...mapActions({
      toggleLayers: 'project/viewer/toggleLayers',  //sends an event to the viewer to turn a set of layers on or off
      secondaryViewerToggleLayers: 'project/viewer/secondaryViewerToggleLayers',
      selectSimulation: 'project/simulationAsset/selectSimulation', //Select a current simulation
      selectSecondaryViewerSimulation: 'project/simulationAsset/selectSecondaryViewerSimulation', //Select a current secondary viewer simulation
      setLayerIsLoading: 'project/simulationAsset/setLayerIsLoading',
      setSecondaryViewerLayerIsLoading: 'project/simulationAsset/setSecondaryViewerLayerIsLoading',
      saveLayers: 'project/viewer/saveLayers',  //sends the current layers in the panel to the store so they can be reloaded later
      saveSecondaryViewerLayers: 'project/viewer/saveSecondaryViewerLayers',
      selectTheme: 'project/viewer/selectTheme',
      selectSecondaryViewerTheme: 'project/viewer/selectSecondaryViewerTheme',
      setFilterValue: 'project/simulationAsset/setFilterValue',
      setSecondaryViewerFilterValue: 'project/simulationAsset/setSecondaryViewerFilterValue',
      clearGlobalFilter: 'project/simulationAsset/clearGlobalFilter',
      clearSecondaryViewerGlobalFilter: 'project/simulationAsset/clearSecondaryViewerGlobalFilter',
      setProbeGroup: 'project/viewer/setProbeGroup',
      setGraphRequiresProbesStore: 'project/viewer/setGraphRequiresProbes',
      setSecondaryViewerGraphRequiresProbeStore: 'project/viewer/setSecondaryViewerGraphRequiresProbes',
      resizeViewerCanvas: 'project/viewer/setWindowResizeRequired', //Force canvas to resize when collapse sidebar
    })
  },
  data() {
    return {
      simulationLabel: null,
      createScenarioModalIsVisible: false,
      scenarioProjectId: null,
      errorModalVisible: false,
      layerPanelActive: true,
      showDataProbeToggleButton: false,
      secondaryViewerShowDataProbeToggleButton: false,
      multipleScreenshots: false,
      removeAllLayers: false,
      secondaryMultipleScreenshots: false,
      graphProbes: [],
      wireframeOn: {},
      secondaryViewerWireframeOn: {},
      resultLayersList: null,
      geometryLayersList: null,
      insightLayersList: null,
      secondaryResultLayersList: null,
      secondaryGeometryLayersList: null
    };
  },
  beforeRouteLeave(to, from, next) {
    next();
    return;
  },
  watch: {
    currentInsights(newValue) {
      if (newValue)
        this.resizeViewerCanvas(true);
    },
    error() {
      this.errorModalVisible = true;
    },
    simulation(newValue) {
      if(newValue) {
        if (!this.loggedInUser?.is_superuser && !this.userCompany?.is_rwdi && !this.loggedInUser?.is_otp_user) {
          if(this.selectedProject?.company_id !== this.loggedInUser?.company_id && newValue.category !== 'RESULT' && !this.selectedProject.is_demo_project) {
            this.$router.push({ name: 'ProjectList' });
          }
        }
      }
    },
    selectedProject(newValue) {
      if (newValue) {
        if ((this.loggedInUser?.is_superuser || this.userCompany?.is_rwdi)  || (newValue?.company_id && this.loggedInUser?.company_id == newValue.company_id)){
          this.$store.dispatch(GET_COMPANY, newValue.company_id);
        }
      }
    },
    viewerMode(newValue) {
      if(newValue == 'Insights' && this.isSplitView) {
        this.$router.push({
          name: 'ViewerContainer',
          params: {
            study: Number(this.$route.params.study),
            configuration: Number(this.$route.params.configuration)
          }
        });
      }
    }
  }
};
</script>

<style scoped>
.sceneView-Composition-Mode {
  border: 5px solid #007bff;
}
.container-loading {
  margin: 0 auto;
  display: flex;
}

.viewer-container {
  display: flex;
  overflow: hidden;
}

.viewer-container.flex-container {
  flex-wrap: nowrap;
}

.flex-container-tabs {
  justify-content: center;
}

.inbound-panel {
  background-color: var(--grey-100);
  width: 17.5rem;
  min-width: 19.688rem;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 6.813rem);
}

.panel {
  background-color: var(--grey-100);
  width: 17.5rem;
  min-width: 19.688rem;
  height: calc(100vh - 7.75rem);
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}

.panel-wide {
  background-color: var(--grey-100);
  width: 27rem;
  height: calc(100vh - 7.75rem);
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
}


.viewer-error-container > * {
  margin: 0;
}

.layer-panel-height {
  height: calc(100vh - 8.125rem) !important;
  margin-bottom: 0rem;
}

.collapsed-layer-panel-height {
  height: calc(100vh - 7.75rem) !important;
  margin-bottom: 0rem;
  z-index: 1;
}
</style>
<style>
.viewer-error-alert .close {
  background-color: inherit;
}
</style>