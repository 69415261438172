<template>
  <div id="data_panel_container" ref="data_panel" v-if="dataPanelOpen">
    <b-tabs small content-class="mt-0" class="metric-display analysis-type-tabs">
      <b-tab 
        v-for="analysisType in analysisTypes" 
        :key="analysisType" 
        :title="analysisTypeToReadableName(analysisType)"
        @click="selectedAnalysisType = analysisType"
        :active="selectedAnalysisType === analysisType" 
        class="mt-1">
        <b-tabs small content-class="mt-1" class="metric-tabs">
          <b-tab 
            v-for="metric in getMetricsForAnalysisType(analysisType)" 
            :key="metric" :title="metricToReadableName(metric)"
            @click="selectedMetric = metric"
            :active="selectedMetric === metric"
            class="mt-0">
            <div class="px-2 spec-picker" v-if="getSpecificationsForAnalysisTypeAndMetric(analysisType, metric).length">
              <b-dropdown size="sm" variant="outline-primary bg-white border-white text-dark" menu-class="specification-dropdown-menu">
                <template #button-content>
                  <div class="dd">
                    {{ getAbbreviatedLabel(analysisType, metric, selectedSpecification) }}
                  </div>
                </template>
                <b-dropdown-item 
                  v-for="specification in getSpecificationsForAnalysisTypeAndMetric(analysisType, metric)" 
                  :key="specification"
                  @click="selectSpecification(specification)"
                  class="specification-option overflow-hidden">
                  <span v-if="specification === selectedSpecification">
                    <b-icon-check2 class="mr-1" />
                    <b>{{ specification }}</b>
                  </span>
                  <span v-else>{{ specification }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div v-show="isFourPointPlots(selectedAnalysisType, selectedMetric)">
              <graph-panel
                :isVisible="isFourPointPlots(selectedAnalysisType, selectedMetric)"
                :probe-points="graphProbes"
                :graph-assets="getFilteredGraphAssets(analysisType, metric, selectedSpecification)"
                @setGraphRequiresProbes="setGraphRequiresProbes"
                @probeTypeChanged="graphProbeTypeChanged"
                @clearProbePoints="clearProbes"
                @removeProbe="removeProbe"/>
            </div>
            <div v-if="!isFourPointPlots(selectedAnalysisType, selectedMetric)" class="mt-4 pt-2">
              <div v-for="i in getGraphCountArrayForAnalysisTypeMetric(analysisType, metric)" :key="i"
                class="d-inline-flex flex-column no-top-margin-all-descendants" :id="getGraphContainerId(analysisType, metric, i)">
                <div :id="getGraphTitleId(analysisType, metric, i)"></div>
                <div :id="getGraphId(analysisType, metric, i)" class="m-0"></div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </b-tab>
    </b-tabs>
    <a class="toggle-sidebar-button" role="button" type="button" @click="togglePanel()">
      <b-icon icon="chevron-double-right"></b-icon>
    </a>
  </div>
  <div class="closed-data-panel" @click="togglePanel()" v-else-if="!drawingMode">
    <b-icon icon="chevron-double-left"></b-icon>
  </div>
</template>

<script>
import ApexCharts from 'apexcharts';
import { csv } from 'd3-fetch';
import { mapGetters, mapActions } from 'vuex';
// import { LayerSet } from '@/models/layer-set';
import GraphPanel from './GraphPanel';

export default {
  name: 'DataPanel',
  components: {
    GraphPanel,
  },
  props: {
    graphProbes: {
      type: Array,
      required: true
    },
    graphAssets: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      selectedAnalysisType: 0,
      selectedMetric: 0,
      selectedSpecification: ''
    };
  },
  async mounted() {
    await this.$store.dispatch('project/simulationAsset/getDataCriteriaGraphDefinitions');
    this.initializeSelectedAnalysisType();
    this.resizeViewerCanvas(true);
  },
  computed: {
    analysisTypes() {
      if(this.graphAssets) {
        return Array.from(new Set(this.graphAssets.flat().map(graphAsset => graphAsset.asset.simulationResult.analysis_type)));
      } else {
        return [];
      }
    },
    ...mapGetters('project/simulationAsset', ['dataCriteriaGraphDefinitions', 'configurationId']),
    ...mapGetters('project', ['dataPanelOpen', 'drawingMode'])
  },
  methods: {
    isFourPointPlots(analysisType, metric) {
      return analysisType === 'Cladding' && metric === 'Wind Pressure Coefficients';
    },
    getFilteredGraphAssets(analysisType, metric, specification) {
      if(this.graphAssets) {
        let filteredAssets = this.graphAssets.flat().filter(graphAsset => {
          return graphAsset.asset.simulationResult.analysis_type === analysisType 
            && graphAsset.asset.simulationResult.dataset === metric 
            && this.getSpecificationNameFromAsset(graphAsset.asset) === specification;
        });
        return filteredAssets;
      } else {
        return [];
      }
    },
    async initializeSelectedAnalysisType() {
      // this is needed for the graph panel to destroy and mount to refresh graph probe mode
      this.selectedAnalysisType = null;
      this.selectedMetric = null;
      this.selectedSpecification = null;
      await this.$nextTick();

      // this will cascade in the watchers to also select a metric and specification
      this.selectedAnalysisType = this.analysisTypes[0];
    },
    async togglePanel() {
      this.setDataPanelOpenStore(!this.dataPanelOpen);
      if(!this.dataPanelOpen) {
        this.$refs.data_panel.style.width = '';
      } else {
        this.generateGraphs();
      }
      await this.$nextTick();
      this.resizeViewerCanvas(true);
    },
    analysisTypeToReadableName(analysisType) {
      if(analysisType === 'Static Structural Loading') {
        return 'Structural Data';
      } else if (analysisType === 'Cladding'){
        return 'Cladding';
      } else {
        return analysisType;
      }
    },
    getMetricsForAnalysisType(analysisType) {
      if(this.graphAssets) {
        let metrics = Array.from(new Set(this.graphAssets.flat()
          .filter(graphAsset => graphAsset.asset.simulationResult.analysis_type === analysisType)
          .map(graphAsset => graphAsset.asset.simulationResult.dataset)));
        return metrics;
      } else {
        return [];
      }
    },
    metricToReadableName(metric) {
      if(metric === 'Forces and Moments') {
        return 'Forces';
      } else if (metric === 'Drag and Lift Coefficients') {
        return 'Lift & Drag';
      } else if (metric === 'Force Coefficients') {
        return 'Force Coeff';
      } else if (metric === 'Wind Pressure Coefficients') {
        return 'Cp Plots';
      } else {
        return metric;
      }
    },
    getSpecificationsForAnalysisTypeAndMetric(analysisType, metric) {
      if(this.graphAssets) {
        let specifications = this.graphAssets.flat().filter(graphAsset => {
          return graphAsset.asset.simulationResult.analysis_type === analysisType && graphAsset.asset.simulationResult.dataset === metric;
        }).map(graphAsset => this.getSpecificationNameFromAsset(graphAsset.asset)).filter(spec => spec.length).flat();
        return specifications;
      } else {
        return [];
      }
    },
    getSpecificationNameFromAsset(asset) {
      if(!asset) return '';
      
      asset = asset.simulationResult;

      if(this.isFourPointPlots(asset.analysis_type, asset.dataset)) {
        return `${asset.dataset} · ${asset.qualifier}`;
      }

      let name = '';
      if(asset.analysis_type === 'Static Structural Loading') {
        name += 'Struct';
      }

      if(Object.hasOwn(asset, 'return_period')) {
        name += ` · ${asset.return_period}`;
      }

      if(asset.dataset === 'Forces and Moments') {
        name += ' · Forces';
      } else if (asset.dataset === 'Drag and Lift Coefficients') {
        name += ' · Drag';
      } else if (asset.dataset === 'Force Coefficients') {
        name += ' · Cf';
      }

      return name;
    },
    getAbbreviatedLabel(analysisType, metric, specification) {
      console.log(this.graphAssets);
      if(this.graphAssets) {
        return this.graphAssets.flat().filter(graphAsset => {
          return graphAsset.asset.simulationResult.analysis_type === analysisType 
            && graphAsset.asset.simulationResult.dataset === metric
            && this.getSpecificationNameFromAsset(graphAsset.asset) === specification;
        }).map(graphAsset => graphAsset.abbreviatedLabel)[0];
      } else return '';
    },
    selectSpecification(specification) {
      this.selectedSpecification = specification;
    },
    getAssetsForAnalysisTypeMetric(analysisType, metric) {
      let graphAssets = this.graphAssets.flat()
        .filter(graphAsset => graphAsset.asset.simulationResult.analysis_type === analysisType 
          && graphAsset.asset.simulationResult.dataset === metric);
      let graphDefinitions;
      graphAssets.forEach(graphAsset => {
        graphDefinitions = this.dataCriteriaGraphDefinitions.find(def => def.name === graphAsset.asset.simulationResult.dataset);
        if(graphDefinitions) {
          graphAsset.asset.simulationResult['graphs'] = graphDefinitions.definition;
        }
      });
      return graphAssets;
    },
    analysisTypeMetricHasGraphs(analysisType, metric) {
      if(this.graphAssets){
        return Object.hasOwn(this.getAssetsForAnalysisTypeMetric(analysisType, metric)[0].asset.simulationResult, 'graphs');
      } else {
        return false;
      }
    },
    getGraphCountArrayForAnalysisTypeMetric(analysisType, metric) {
      if(this.analysisTypeMetricHasGraphs(analysisType, metric)) {
        return [...Array(this.getAssetsForAnalysisTypeMetric(analysisType, metric)[0].asset.simulationResult.graphs.length).keys()];
      } else {
        return [];
      }
    },
    convertReadableNameToHTMLId(name) {
      return name.toLowerCase().replaceAll(' ', '_');
    },
    getGraphContainerId(analysisType, metric, i) {
      return this.convertReadableNameToHTMLId(`${this.analysisTypeToReadableName(analysisType)}_${this.metricToReadableName(metric)}_graphcontainer_${i}`);
    },
    getGraphId(analysisType, metric, i) {
      return this.convertReadableNameToHTMLId(`${this.analysisTypeToReadableName(analysisType)}_${this.metricToReadableName(metric)}_graph_${i}`);
    },
    getGraphTitleId(analysisType, metric, i) {
      return this.convertReadableNameToHTMLId(`${this.analysisTypeToReadableName(analysisType)}_${this.metricToReadableName(metric)}_graphtitle_${i}`);
    },
    generateGraphs() {
      // generates graphs for metrics that have graphs
      let metrics, assetsWithGraphs, csvData;
      this.analysisTypes.forEach(analysisType => {
        metrics = this.getMetricsForAnalysisType(analysisType); 
        metrics.forEach(metric => {
          if(this.analysisTypeMetricHasGraphs(analysisType, metric)) {
            // generate graphs for metric
            assetsWithGraphs = this.getAssetsForAnalysisTypeMetric(analysisType, metric);
            assetsWithGraphs.forEach(async assetWithGraph => {
              if(this.getSpecificationNameFromAsset(assetWithGraph.asset) === this.selectedSpecification) {
                // read csv for graph
                csvData = await csv(assetWithGraph.asset.simulationResult.asset_file);
                
                // clear old graphs
                assetWithGraph.asset.simulationResult.graphs.forEach((_, i) => {
                  this.clearGraph(analysisType, metric, i);
                });
                
                // generate a graph for each entry in the graphs field
                assetWithGraph.asset.simulationResult.graphs.forEach((graph, i) => {
                  this.generateApexChartsGraph(analysisType, metric, csvData, graph, i);
                });
              }
            });
          }
        });
      });
    },
    clearGraph(analysisType, metric, graphIndex) {
      let graphId = this.getGraphId(analysisType, metric, graphIndex);
      let graph = document.getElementById(graphId);
      graph.innerHTML = '';
      let graphTitleId = this.getGraphTitleId(analysisType, metric, graphIndex);
      let graphTitle = document.getElementById(graphTitleId);
      graphTitle.innerHTML = '';
    },
    generateApexChartsGraph(analysisType, metric, csvData, graph, graphIndex) {
      let graphId, graphTitleId, xAxisData, yAxisData;

      graphId = this.getGraphId(analysisType, metric, graphIndex);
      graphTitleId = this.getGraphTitleId(analysisType, metric, graphIndex);

      xAxisData = csvData.map(row => row[graph['x-axis'].data_column]);
      yAxisData = csvData.map(row => row[graph['y-axis'].data_column]);

      let graphTitle = this.getGraphHTMLTitle(graph);

      let data = xAxisData.map((point, i) => {
        return { x: parseInt(point), y: yAxisData[i] };
      });
      let options = {
        chart: {
          type: 'line',
          zoom: { enabled: false },
          height: 150,
          toolbar: {
            offsetY: 9,
          },
          animations: {
            enabled: false,
            animateGradually: {
              enabled: false,
            },
            dynamicAnimation: {
              enabled: false
            }
          }
        },
        title: {
          text: ''
        },
        grid: {
          show: true,
          borderColor: '#e0e0e0',
          strokeDashArray: 0, // 0 for solid lines
          position: 'back', 
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          }
        },
        dataLabels: { enabled: false },
        series: [{
          data: data
        }],
        stroke: {
          width: 2,
          colors: ['rgb(1, 115, 185)']
        },
        markers: {
          size: 3,
          colors: ['rgb(1, 115, 185)']
        },
        yaxis: {
          tickAmount: 4,
          labels: {
            formatter: value => {
              return value.toFixed(1);
            },
            style: {
              fontSize: 9
            }
          }
        },
        xaxis: {
          tickAmount: 3,
          stepSize: 90,
          max: 360,
          labels: {
            rotate: 0,
            style: {
              fontSize: 9
            },
            formatter: (value) => { return parseInt(value); }
          }
        },
        tooltip: {
          custom: ({series, seriesIndex, dataPointIndex}) => {
            return `
              <div>
                <span class="p-2">${series[seriesIndex][dataPointIndex].toFixed(1)}</span>
              </div>
            `;
          }
        }
      };
      document.getElementById(graphTitleId).innerHTML = graphTitle;
      let chart = new ApexCharts(document.getElementById(graphId), options);
      chart.render();
    },
    getGraphHTMLTitle(graph) {
      switch (graph.title) {
      case 'Fx':
        return 'F<sub>x</sub>';
      case 'Fy':
        return 'F<sub>y</sub>';
      case 'Mx':
        return 'M<sub>x</sub>';
      case 'My':
        return 'M<sub>y</sub>';
      case 'Mz':
        return 'M<sub>z</sub>';
      case 'C_Fx':
        return 'C<sub>F<sub>x</sub></sub>';
      case 'C_Fy':
        return 'C<sub>F<sub>y</sub></sub>';
      case 'Drag Coefficients':
        return 'C<sub>D</sub>';
      case 'Lift Coefficients':
        return 'C<sub>L</sub>';
      default:
        return graph.title;
      }
    },
    setGraphRequiresProbes(args) {
      this.$emit('setGraphRequiresProbes', args);
    },
    graphProbeTypeChanged(args) {
      this.$emit('graphProbeTypeChanged', args);
    },
    clearProbes() {
      this.$emit('clearProbes');
    },
    removeProbe(probe) {
      this.$emit('removeProbe', probe);
    },
    ...mapActions({
      resizeViewerCanvas: 'project/viewer/setWindowResizeRequired',
      setDataPanelOpenStore: 'project/setDataPanelOpen'
    })
  },
  watch: {
    graphAssets() {
      this.generateGraphs();
    },
    selectedAnalysisType(newValue) {
      if(newValue) {
        let firstMetric = this.getMetricsForAnalysisType(this.selectedAnalysisType)[0];
        this.selectedMetric = firstMetric; // this will fire the selectedMetric watcher, so no need to select a specification and generation graphs, since those happen in the selectedMetric watcher
      }
    },
    selectedMetric(newValue) {
      if(newValue) {
        let firstSpecification = this.getSpecificationsForAnalysisTypeAndMetric(this.selectedAnalysisType, this.selectedMetric)[0];
        if(firstSpecification) this.selectSpecification(firstSpecification);
      }
    },
    selectedSpecification(newValue) {
      if(newValue) {
        this.generateGraphs();
      }
    },
    configurationId() {
      this.initializeSelectedAnalysisType();
    },
    drawingMode() {
      this.resizeViewerCanvas(true);
    }
  }
};
</script>

<style scoped>
#data_panel_container {
  width: 400px;
  height: calc(100vh - (var(--header-footer-height) * 3 + 1.5rem));
  margin-top: calc(var(--header-footer-height) + 0.5rem);
  background: var(--grey-100);
  z-index: 20;
}

.analysis-type-tabs {
  font-size: 14px;
}

.metric-tabs {
  font-size: 12px;
}

[id*=_graphcontainer_] {
  width: 195px;
  height: 160px;
  min-height: 150px !important;
}

/* [id*=_graph_] {
  min-height: none;
} */

[id*=_graphtitle_] {
  margin: auto;
  position: relative;
  top: 10px;
  height: 0px;
  font-weight: bold;
}

.metric-display {
  height: calc(100vh - (var(--header-footer-height) * 3 + 2rem));
  vertical-align: top;
  display: inline-block;
  width: 99%;
  margin-top: 0px;
}

.toggle-sidebar-button {
  color: var(--primary-blue);
  background-color: #f0f0f0;
  left: 0;
  height: 1.313rem;
  transition: width 0.3s;
  padding: 0 1rem;
  border: 0;
  display: flex;
  align-items: center;
  border-top: 0.063rem solid #dbdbdb;
  position: relative;
  bottom: 2.385em;
  left: 0;
}

.toggle-sidebar-button:hover {
  background-color: #dbdbdb;
  color: #303030;
}

.closed-data-panel {
  background-color: var(--grey-100);
  color: var(--primary-blue);
  width: 0.938rem;
  min-width: 0.938rem;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  height: calc(100vh - (var(--header-footer-height) * 3 + 1.5rem));
  margin-top: calc(var(--header-footer-height) + 0.5rem);
  z-index: 20;
}

.closed-data-panel:hover {
  background-color: #dbdbdb;
  color: #303030;
  cursor: pointer;
}

.metric-title {
  font-size: 18px;
}

.dd {
  max-width: 9.375rem;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--grey-900);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<style>
.specification-option a {
  text-decoration: none;
  color: black;
  font-size: 16px;
  z-index: 12;
}

.specification-dropdown-menu {
  padding: 0;
}

.spec-picker {
  font-size: 11px;
  position: absolute;
  right: 0px;
  margin-top: -15px;
}

.analysis-type-tabs .nav-link {
  text-decoration: none;
}
</style>