<template>
    <b-navbar
      v-show="showToolbar" 
      :toggleable="isSplitView" 
      class="btn-container on-top pt-0 no-top-margin-all-descendants">
      <div class="d-flex flex-row">
        <b-dropdown class='d-flex flex-column split-dropdown mr-2'
          variant="outline-primary bg-white border-white text-dark"
          v-if="showSplitViewDropDownOutOfToolbar">
          <template #button-content>
            <span>
              <b-img :src='currentViewIcon' class='mr-2'></b-img>
            </span>
          </template>
          <b-dropdown-item
            @click='goToSingleView'
            class='overflow-hidden'
            variant="dark"
            link-class="text-decoration-none pl-2">
            <b-img src='/general_icons/single view.png' class='mx-2'></b-img>
            Single View
          </b-dropdown-item>
          <b-dropdown-item
            @click='goToSplitView'
            class='overflow-hidden'
            variant="dark"
            link-class="text-decoration-none pl-2">
            <b-img src='/general_icons/split view.png' class='mx-2'></b-img>
            Split View
          </b-dropdown-item>
        </b-dropdown>
        <div class="no-top-margin-all-descendants">
          <b-navbar-toggle :target="navCollapseId"></b-navbar-toggle>
          <b-collapse :id="navCollapseId" is-nav>
            <b-navbar-nav :class="isSplitView ? 'bg-white rounded p-2' : ''">

              <b-nav-item v-if="showSplitViewDropDownInToolbar">
                <b-dropdown class='d-flex flex-column split-dropdown'
                  variant="outline-primary bg-white border-white text-dark">
                  <template #button-content>
                    <span>
                      <b-img :src='currentViewIcon' class='mr-2'></b-img>
                    </span>
                  </template>
                  <b-dropdown-item
                    @click='goToSingleView'
                    class='overflow-hidden'
                    variant="dark"
                    link-class="text-decoration-none pl-1">
                    <b-img src='/general_icons/single view.png' class='mx-2'></b-img>
                    Single View
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click='goToSplitView'
                    class='overflow-hidden'
                    variant="dark"
                    link-class="text-decoration-none pl-1">
                    <b-img src='/general_icons/split view.png' class='mx-2'></b-img>
                    Split View
                  </b-dropdown-item>
                </b-dropdown>
              </b-nav-item>

              <b-nav-item link-classes='text-decoration-none'>
                <b-dropdown
                  variant="outline-primary bg-white border-white text-dark"
                  :class="['d-flex', 'flex-column', isSplitView ? 'shadow-sm' : '']">
                  <template #button-content>
                    <b-img :src="themeIcons[`${selectedTheme}-1`]" class="mx-1" style="height: 1.25rem; width:1.563rem;"></b-img>
                    <div :class="['dd']" style='min-width:13.75rem' v-if="isSplitView">
                      <p>{{ selectedTheme }}</p>
                    </div>
                  </template>
                  <b-dropdown-item-button
                    class="dropdown-button"
                    button-class="pl-2"
                    variant="dark"
                    v-for="(theme, index) in themeLabels"
                    :key="index"
                    @click="setTheme(theme, index)">
                    <b-img :src="themeIcons[`${theme}-1`]" class="mx-1 mb-2" style="height: 1.25rem; width:1.563rem;"></b-img>
                    {{ theme.charAt(0).toUpperCase() + theme.slice(1) }}
                  </b-dropdown-item-button>
                </b-dropdown>
              </b-nav-item>

              <b-nav-item v-if="!isPreviewModeOpen && !isSimulationInbound" link-classes='text-decoration-none'>
                <b-button-group :class="['no-wrap', isSplitView ? 'shadow-sm' : '', isSplitView ? 'd-flex' : '']">
                  <b-button
                    :variant="
                      isCameraOrthographic
                        ? 'outline-primary bg-white border-white text-dark'
                        : 'outline-white border-transparent text-dark'
                    "
                    :class="isCameraOrthographic ? 'mx-0' : 'camera-toggle'"
                    @click="setCameraView('orthographic')"
                  >
                    <svg
                      width="17"
                      height="19"
                      viewBox="0 0 17 19"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.04902 1.80658V15.509M3.13925 15.509H14.8584M13.8669 13.5257L15.8501 15.5089L13.8669 17.6725M1.06592 3.65572L2.07799 2.67532L3.04916 1.67249L5.21269 3.65572"
                        :stroke="isCameraOrthographic ? '#51D0FF' : 'currentcolor'"
                        stroke-width="2"
                        stroke-miterlimit="5.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    2D
                  </b-button>
                  <b-button
                    :variant="
                      !isCameraOrthographic
                        ? 'outline-primary bg-white border-white text-dark'
                        : 'outline-white border-transparent text-dark'
                    "
                    :class="!isCameraOrthographic ? 'mx-0' : 'camera-toggle'"
                    @click="setCameraView('perspective')"
                  >
                    <svg
                      width="19"
                      height="17"
                      viewBox="0 0 19 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.72002 1.67249V10.7329M9.72002 1.67249L11.5321 3.48457M9.72002 1.67249L7.78714 3.48457M9.72002 10.7329L17.4516 15.2027M9.72002 10.7329L1.86768 15.2027M17.4516 15.2027L14.9147 15.9275M17.4516 15.2027L16.7267 12.6658M1.86768 15.2027L2.59251 12.6658M1.86768 15.2027L4.40459 15.9275"
                        :stroke="!isCameraOrthographic ? '#51D0FF' : 'currentcolor'"
                        stroke-width="2"
                        stroke-miterlimit="5.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                    3D
                  </b-button>
                </b-button-group>
              </b-nav-item>

              <b-nav-item v-if="!isPreviewModeOpen && !isSimulationInbound" link-classes='text-decoration-none'>
                <b-dropdown
                  variant="outline-primary bg-white border-white text-dark"
                  @hide="isActive = false"
                  v-b-tooltip.hover
                  title="save/select camera angle"
                  :class="['d-flex', 'flex-column', isSplitView ? 'shadow-sm' : '']"
                >
                  <template #button-content>
                    <b-icon icon="camera-video" class="mx-1"></b-icon>
                    <div :class="['dd']" :style="isSplitView ? 'min-width:13.75rem' : ''">
                      <p>{{ isSplitView ? 'Camera angle' : null }}</p>
                    </div>
                  </template>
                  <div class="camera-settings">
                    <b-dropdown-item-button
                      class="dropdown-button"
                      title="Reset Camera"
                      variant="dark"
                      @click="resetCamera()"
                    >
                      Default
                    </b-dropdown-item-button>
                    <b-dropdown-item-button
                      class="dropdown-button"
                      variant="dark"
                      v-for="cameraPosition in savedCameraPositions"
                      :key="cameraPosition.id"
                      :title="cameraPosition.name"
                      :disabled="deletingCameraPosition==cameraPosition.id"
                      @click="setCamera(cameraPosition)"
                    >
                      <span
                        v-if="
                          getCameraView(cameraPosition)
                            .toLowerCase()
                            .startsWith('orthographic')
                        "
                      >
                        <img
                          src="~@/assets/svg/2d-icon.svg"
                          alt="2D camera"
                          height="13"
                        />
                      </span>
                      <span v-else>
                        <img
                          src="~@/assets/svg/3d-icon.svg"
                          alt="3D camera"
                          height="13"
                        />
                      </span>
                      {{ getCameraName(cameraPosition.name) }}
                      <span class="camera-edit-container">
                        <loading-state v-if="deletingCameraPosition==cameraPosition.id" class="loading-spinner"/>
                        <b-button
                          :class="['menu']"
                          @click.stop="showCameraMenu(cameraPosition)"
                          >⋮
                          <div
                            :class="{
                              'hover-menu': true,
                              active: isActive && cameraToUpdate === cameraPosition,
                            }"
                          >
                            <div>
                              <button @click.stop="renameCameraPosition()">Rename</button>
                            </div>
                            <div v-if="!insightCameraPositions.includes(cameraPosition.id)">
                              <button @click.stop="deleteCameraPosition()">Delete</button>
                            </div>
                          </div>
                        </b-button>
                      </span>
                    </b-dropdown-item-button>
                  </div>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item-button
                    class="dropdown-button"
                    variant="dark"
                    @click="saveCurrentCamera()"
                  >
                    Save Camera Angle
                  </b-dropdown-item-button>
                </b-dropdown>
              </b-nav-item>

              <b-nav-item v-if="!isSimulationInbound && !sceneViewCompositionMode" :link-classes="['text-decoration-none', isSplitView ? 'd-flex' : '']">
                <b-button
                  :variant="screenShotMode ?
                    'outline-primary bg-white border-white text-dark' :
                    'outline-white border-transparent text-dark'"
                  :class="[screenShotMode ? '' : 'camera-toggle', isSplitView ? 'flex-fill' : '', isSplitView ? 'shadow-sm' : '']"
                  @click="toggleScreenShotMode()"
                  v-b-tooltip.hover
                  title="toggle screenshot mode">
                  <b-icon icon='camera' class='mx-1'></b-icon>
                  <div v-if='isSplitView' class='dd' :style="isSplitView ? 'min-width:14.375rem' : ''">
                    <p>Screenshot</p>
                  </div>
                </b-button>
              </b-nav-item>

              <b-nav-item v-if="!layerIsLoading & showProbeMode && viewerMode == 'Viewer'" :link-classes="['text-decoration-none', isSplitView ? 'd-flex' : '']">
                <div id="toggle_data_probes_button">
                  <b-button
                    :variant="dataProbeStatus ? 'outline-primary bg-white border-white text-dark' : 'outline-white border-transparent text-dark'"
                    :class="[dataProbeStatus ? '' : 'camera-toggle', isSplitView ? 'flex-fill' : '', isSplitView ? 'shadow-sm' : '']"
                    @click="toggleProbeMode()"
                    :disabled="graphProbeStatus">
                    <b-icon icon='geo' class='mx-1'></b-icon>
                    <div v-if='isSplitView' class='dd' :style="isSplitView ? 'min-width:14.375rem' : ''">
                      <p>Probe data</p>
                    </div>
                  </b-button>
                </div>
                <b-tooltip target="toggle_data_probes_button">
                  {{ graphProbeStatus ? 'data probes are disabled when graph probes are active' : 'toggle probe mode' }}
                </b-tooltip>
              </b-nav-item>

              <b-nav-item v-if="!isSimulationInbound && !sceneViewCompositionMode" :link-classes="['text-decoration-none', isSplitView ? 'd-flex' : '']">
                <b-button
                  :variant="clippingPlaneMode ?
                    'outline-primary bg-white border-white text-dark' :
                    'outline-white border-transparent text-dark'"
                  :class="['camera-toggle', isSplitView ? 'shadow-sm' : '', isSplitView ? 'flex-fill' : '']"
                  @click.stop="toggleClippingPlaneMode()"
                  :disabled='geometryHeight === 0'
                  v-b-tooltip.hover
                  title='toggle clipping plane'>
                  <b-icon icon='chevron-bar-down' class='mx-1'></b-icon>
                  <div class='dd' v-if='isSplitView' :style="isSplitView ? 'min-width:14.375rem' : ''">
                    <p>Clipping plane</p>
                  </div>
                </b-button>
              </b-nav-item>

              <b-nav-text v-if='clippingPlaneMode' @click.prevent>
                <b-input-group
                  @click.stop
                  class='clipping-planes-input no-wrap'>
                  <b-form-input
                    type='range'
                    class='clipping-planes-slider'
                    :value='clippingPlaneHeight'
                    @update="setClippingPlaneHeight"
                    @click.stop
                    step='0.5'
                    :min='geometryFloor'
                    :max='geometryHeight'
                    v-b-tooltip.hover
                    title='slide to adjust clipping plane height'>
                  </b-form-input>
                  <b-form-input
                    type='number'
                    class='clipping-planes-number'
                    :value="clippingPlaneHeight"
                    step='0.5'
                    :min='geometryFloor'
                    :max='geometryHeight'
                    @update="setClippingPlaneHeight"
                    v-b-tooltip.hover
                    title='clipping plane height'>
                  </b-form-input>
                  <b-icon
                    icon='x'
                    @click='toggleClippingPlaneMode'
                    class='clipping-planes-x'>
                  </b-icon>
                </b-input-group>
              </b-nav-text>
              
              <b-nav-item v-if="editInsightMode && !sceneViewCompositionMode && this.currentInsightIsSceneView" id="toggle-annotations-tool-button">
                <b-button
                  :variant="annotationsCompositionMode ?
                    'outline-primary bg-white border-white text-dark' :
                    'outline-white border-transparent text-dark'"
                  class="camera-toggle"
                  @click.stop="setAnnotationsPanelVisible(!annotationsPanelVisible)"
                  v-b-tooltip.hover
                  title='toggle annotations creation menu'>
                  <b-icon icon='geo-alt-fill' class='mx-1'></b-icon>
                </b-button>
              </b-nav-item>

              <b-nav-item v-if="isViewerMode && userCanAuthorInsights && !isSplitView && !isSimulationInbound && !selectedProject.is_demo_project">
                <b-button 
                  v-b-modal.confirm-create-insight
                  class='camera-toggle' 
                  variant='border-transparent'
                  style="width: 28px;"
                  v-b-tooltip.hover
                  title="Create a new insight with the current scene">
                  <b-img :src="'/general_icons/add insight.png'" class="d-inline"></b-img>
                </b-button>
              </b-nav-item>

              <b-modal id="confirm-create-insight" centered hide-header>
                <p class="mb-1">What would you like to call the insight?</p>
                <b-form-input v-model='newInsightTitle' placeholder='New Insight...'></b-form-input>
                <p class="mt-2 create-insight-description-text">Insight will be created as a draft.</p>
                <template #modal-footer>
                  <b-button @click='cancelCreateNewInsight' class='float-left'>Cancel</b-button>
                  <b-button 
                    @click='createNewInsight' 
                    variant='primary' 
                    class='float-right'
                    :disabled="!newInsightTitle || createClicked">Create</b-button>
                </template>
              </b-modal> 

              <b-nav-item 
                v-if="(isViewerMode && isPreviewModeOpen && !isSimulationInbound) || submittedSimulationHasErrors"
                class='position-fixed'
                style='right: 0.313rem;'>
                <div class="ml-auto d-flex flex-row">
                  <b-button class="ml-2 toolbar-icon-button" variant="outline-primary" @click="editSimulation()">
                    <b-icon icon="pencil"/>
                  </b-button>
                  <b-button class="mx-2 toolbar-icon-button" variant="outline-primary" @click="showDeleteModal()">
                    <b-icon icon="trash"/>
                  </b-button>
                  <span id='toolbar-text-button'>
                    <b-button
                      variant='primary'
                      class="mr-2 toolbar-text-button"
                      :disabled="remeshPending || !simulationHasAssets || resubmitPending"
                      @click="resubmitMlScenario()">
                      <span>Resubmit</span>
                    </b-button>
                  </span>
                </div>
              </b-nav-item>

              <b-nav-item v-if="layerIsLoading && !isSplitView">
                <div class="ml-5 mt-2 d-flex flex-row">
                  <span class="mr-2">Loading</span>
                  <b-spinner small></b-spinner>
                </div>
              </b-nav-item>


              <b-nav-item v-if="sceneViewCompositionMode" style="left: 44.5rem">
                <b-button 
                  @click="cancelSceneCompositionMode" 
                  variant="secondary">
                  Cancel
                </b-button>
              </b-nav-item>

              <b-nav-item v-if="sceneViewCompositionMode" style="width: 135px; left: 50rem;">
                <b-button 
                  @click="saveScene" 
                  variant="primary">
                  Save Scene
                </b-button>
              </b-nav-item>

              <b-nav-item v-if="editInsightMode && hasScene && !sceneViewCompositionMode && !annotationsCompositionMode">
                <div class="ml-auto" id="recompose-scene-button">
                  <b-button @click="enterSceneCompositionMode" :disabled="hasDrawings" variant="secondary">Recompose Scene</b-button>
                </div>
                <b-tooltip target="recompose-scene-button" v-if="hasDrawings">
                  Cannot recompose the scene when an insight has a saved drawing overlay
                </b-tooltip>
              </b-nav-item>

              <b-nav-item class="position-fixed mt-2" v-if="savingSceneViewInsight" style="right: 1.2rem;">
                <b-spinner small variant="primary"></b-spinner>
              </b-nav-item>          

              <b-nav-item v-if="isSimulationInbound && !selectedProject.is_demo_project" class='position-fixed' style='right:0.313rem;'>
                <div class="mr-2 ctrl-button-container">
                  <b-button
                    v-if="showTitleEditIcon"
                    class="ml-2 toolbar-icon-button"
                    variant="outline-primary"
                    @click="editSimulation()"
                    :disabled='selectedProject.is_demo_project'>
                    <b-icon icon="pencil"/>
                  </b-button>
                  <b-button
                    class="mx-2 toolbar-icon-button"
                    variant="outline-primary"
                    @click="showDeleteModal()"
                    :disabled='selectedProject.is_demo_project'>
                    <b-icon icon="trash"/>
                  </b-button>
                </div>
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
          <drawing-tool-toggle 
            v-if="showDrawingToolButton"
            :inDrawingTool="false"
            @toggleDrawingMode="toggleDrawingMode">
          </drawing-tool-toggle>
        </div>
        <div v-if="layerIsLoading && isSplitView" class="ml-5 d-flex flex-row align-items-center no-top-margin-all-descendants">
          <span class="mr-2">Loading</span>
          <b-spinner small></b-spinner>
        </div>
        <edit-scenario-modal v-if="openEditModal" @close-modal="openEditModal=false" 
          :showModal="openEditModal" 
          :scenarioName="scenarioNameToEdit" 
          :configurationId="configurationIdToEdit"
          :simulationId="simulationIdToEdit"></edit-scenario-modal>
      </div>
      <annotation-creation-menu 
          :show-annotation-creation-menu="annotationsPanelVisible"
          @setShowAnnotationCreationMenu="setAnnotationsPanelVisible"/>
    </b-navbar>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  BButtonGroup,
  BDropdownItemButton,
  BDropdownDivider,
  BDropdown,
  BButton,
  BIcon,
} from 'bootstrap-vue';
import LoadingState from '@/components/views/LoadingState';
import _ from 'lodash';
import { EventBus } from '@/network/eventbus';
import { AUTHOR_INSIGHTS } from '@/constants/permissions';
import { THEME_ICONS } from '@/constants/themes';
import EditScenarioModal from '@/components/projects/list/EditScenarioModal';
import AnnotationCreationMenu from './AnnotationCreationMenu';
import DrawingToolToggle from './DrawingToolToggle.vue';

export default {
  name: 'ViewerToolbar',
  components: {
    EditScenarioModal,
    LoadingState,
    BButtonGroup,
    BDropdownItemButton,
    BDropdownDivider,
    BDropdown,
    BButton,
    BIcon,
    AnnotationCreationMenu,
    DrawingToolToggle,
  },
  props: {
    showSimEditButton: {
      type: Boolean,
      required: true
    },
    showProbeMode: {
      required: false,
      type: Boolean
    },
    themeLabels: {
      type: Array,
      required: true,
    },
    selectedThemeIndex: {
      type: Number
    },
    savedCameraPositions: {
      required: true,
    },
    insightCameraPositions: {
      required: true
    },
    deletingCameraPosition: {
      required: true,
    },
    activeCameraView: {
      required: true,
    },
    screenShotMode: {
      required: true,
    },
    clippingPlaneMode: {
      required: true
    },
    clippingPlaneHeight: {
      type: Number,
      required: true
    },
    geometryHeight: {
      type: Number,
      required: true
    },
    geometryFloor: {
      type: Number,
      required: true
    },
    rank: {
      type: String,
      required: true
    },
    layerIsLoading: {
      type: Boolean,
      required: true
    },
    selectedSimulationLabel: {
      required: true
    },
  },
  data() {
    return {
      openEditModal: false,
      annotationsPanelVisible: false,
      selectedTheme: this.themeLabels[0],
      isActive: false,
      cameraToUpdate: null,
      timer: null,
      //resultsSimulationId: 0,
      remeshNotificationCount: 0,
      moreResultsAvailable: false,
      newResultsAvailableText: '',
      resubmitPending: false,
      newInsightTitle: '',
      createClicked: false
    };
  },
  mounted() {
    EventBus.$on('RESULTS_DELIVERED', this.onResultsDelivered);
    EventBus.$on('ML_STATUS_UPDATE', this.onMLStatusUpdate);
    EventBus.$on('SIMULATION_ERROR', this.onMLError);

    this.$root.$on('setAnnotationsPanelVisible', (isVisible) => {
      this.setAnnotationsPanelVisible(isVisible);
    });
  },
  beforeDestroy() {
    EventBus.$off('RESULTS_DELIVERED', this.onResultsDelivered);
    EventBus.$off('ML_STATUS_UPDATE', this.onMLStatusUpdate);
    EventBus.$off('SIMULATION_ERROR', this.onMLError);
  },
  computed: {
    themeIcons() {
      return THEME_ICONS;
    },
    showSplitViewDropDownOutOfToolbar() {
      return this.isSplitView && this.isPrimaryViewer;
    },
    showSplitViewDropDownInToolbar() {
      return !this.isSplitView && 
        this.isSimulationResults && 
        this.isViewerMode && 
        !this.moreResultsAvailable && 
        !this.waitingForAdditionalResults && 
        !this.sceneViewCompositionMode;
    },
    navCollapseId() {
      return `${this.rank}-nav-collapse`;
    },
    showTitleEditIcon() {
      return this.showSimEditButton;
    },
    companyIsRWDI() {
      return this.userCompany?.is_rwdi;
    },
    showDrawingToolButton() {
      return !this.isSplitView && 
        !this.isSimulationInbound && 
        !this.sceneViewCompositionMode &&
        !this.submittedSimulationHasErrors && 
        (this.hasDrawings || this.userCanAuthorInsights);
    },
    isViewerMode() {
      return this.viewerMode === 'Viewer';
    },
    isML() {
      return this.simulation?.simulation_type === 'ML';
    },
    isSplitView() {
      return !!this.$route.query?.secondary;
    },
    isPrimaryViewer() {
      return this.rank === 'primary';
    },
    isSecondaryViewer() {
      return this.rank === 'secondary';
    },
    currentViewText() {
      return this.isSplitView ? 'Split View' : 'Single View';
    },
    currentViewIcon() {
      return this.isSplitView ? '/general_icons/split view.png' : '/general_icons/single view.png';
    },
    submittedSimulationHasErrors() {
      return this.simulation?.errors?.length > 0 && this.simulation.category === 'SUBMITTED';
    },
    remeshPending() {
      if(this.simulation.simulation_type === 'ML') {
        return this.remeshedPresentationPlaneCount + this.remeshNotificationCount < this.presentationPlaneCount;
      } else {
        return false;
      }
    },
    selectedConfigurationId() {
      return this.$store.getters['project/simulationAsset/configurationId'];
    },
    studyId() {
      return Number(this.$route.params.study);
    },
    projectId() {
      return Number(this.$route?.params?.id);
    },
    simulationId() {
      return this.simulation?.id;
    },
    allIdsLoaded() {
      let allLoaded = this.projectId && this.studyId && this.selectedConfigurationId && this.simulationId;
      return allLoaded;
    },
    presentationPlaneCount() {
      return this.simulation.assets.filter(a => a.geometry_type === 'presentation plane').length;
    },
    remeshedPresentationPlaneCount() {
      return this.simulation.assets.filter(a => a.geometry_type === 'presentation plane' && a.remeshed_assets.length).length;
    },
    simulationIsCladding() {
      let jobType;
      return this.simulation?.job_types_with_criteria?.some(x => {
        jobType = this.jobTypes.find(z => z.id === x.job_type);
        return jobType.sub_engine === 'Cladding';
      });
    },
    simulationHasAssets() {
      let asset_types = this.simulation.assets.map(a => a.geometry_type);
      if(this.simulation.simulation_type === 'CFD') {
        return (asset_types.includes('study') || asset_types.includes('surround'))
          && asset_types.includes('ground')
          && asset_types.includes('presentation plane');
      } else if(this.simulationIsCladding) {
        return asset_types.includes('study')
          && asset_types.includes('surround')
          && asset_types.includes('ground');
      } else {  //simulation_type == ML
        return asset_types.includes('study')
          && asset_types.includes('surround')
          && asset_types.includes('ground')
          && asset_types.includes('presentation plane');
      }
    },
    simulationHasWindDirections() {
      if(this.simulation.simulation_type === 'ML') {
        return !!this.simulation.ml_parameters.windDirections?.length;
      } else {
        return true;
      }
    },
    projectHasMetData() {
      return this.selectedProject.met_data_id != null;
    },
    easterEggThemeIsNotActive() {
      return this.easterEggThemeIsNotActive;
    },
    isCameraOrthographic() {
      return this.activeCameraView.toLowerCase().startsWith('orthographic');
    },
    isSimulationInbound() {
      return this.simulationCategory == 'INBOUND';
    },
    isSimulationResults() {
      return this.simulationCategory === 'RESULT';
    },
    resultsRoute() {
      return {
        name: 'ViewerContainer',
        params: {
          id: this.$route.params.id,
          study: this.$route.params.study,
          configuration: this.$route.params.configuration
        }
      };
    },
    currentSimulationType() {
      return this.simulation?.simulation_type;
    },
    hasScene() {
      return this.currentInsight?.sceneview_set.length > 0;
    },
    isSceneView() {
      return this.currentInsight?.insight_type == 'Scene';
    },
    hasDrawings() {
      return this.hasScene && this.currentInsight?.sceneview_set[0].drawings.length > 0;
    },
    userCanAuthorInsights() {
      return (this.$store.getters['allowedPermissions'] || {})[AUTHOR_INSIGHTS];
    },
    showToolbar() {
      if(this.drawingMode) {
        return false;
      } else if(this.viewerMode == 'Insights' && this.isSceneView && !this.hasScene && !this.sceneViewCompositionMode) {
        return false;
      } else {
        return true;
      }
    },
    currentInsightIsSceneView() {
      return this.currentInsight?.sceneview_set.length > 0;
    },
    // resultsAvailable() {
    //   return this.simulationCategory === 'SUBMITTED' && this.resultsSimulationId !== 0;
    // },
    ...mapGetters('project/simulationAsset', ['simulationCategory', 'userSelectedLayers', 'simulation', 'configurationId', 'remeshedPresentationPlaneExists', 'simulationResultAssets']),
    ...mapGetters('project/viewer/', ['easterEggThemeIsNotActive', 'easterEggThemeName', 'mlStatus', 'dataProbeStatus', 'graphProbeStatus']),
    ...mapGetters('project', ['currentInsightIndex', 'sceneViewCompositionMode', 'annotationsCompositionMode', 'isPreviewModeOpen', 'selectedProject', 'jobTypes', 'viewerMode', 'drawingMode', 'editInsightMode', 'currentInsight', 'savingSceneViewInsight']),
    ...mapGetters(['webSocketConnectionInfo', 'userCompany'])
  },
  methods: {
    createInsightLink() {
      const locationOriginal = String(window.location.origin);
      const link = `${locationOriginal}/projects/${this.$route.params.id}/viewer/${this.$route.params.study}/configuration/${this.$route.params.configuration}/insights?page=${this.currentInsightIndex}`;
      return link;
    },
    setAnnotationsPanelVisible(isVisible) {
      this.annotationsPanelVisible = isVisible;

    },
    async createNewInsight() {
      this.createClicked = true;
      this.$store.dispatch('project/setNewInsightTitle', this.newInsightTitle);
      this.$emit('createNewInsight', () => {
        this.createClicked = false;
        this.newInsightTitle = '';
        this.$store.dispatch('project/setNewInsightTitle', '');
        this.$emit('saveSceneForNewInsight', () => {

          this.$bvModal.hide('confirm-create-insight');
          EventBus.$emit('TOAST', {
            variant: 'success',
            content: 'New insight created',
            href: this.createInsightLink()
          });
        });
      });
    },
    cancelCreateNewInsight() {
      this.$bvModal.hide('confirm-create-insight');
      this.newInsightTitle = '';
    },
    cancelSceneCompositionMode() {
      this.$store.dispatch('project/setSceneViewCompositionMode', false);
      this.$store.dispatch('project/setViewerMode', 'Insights');
    },
    enterSceneCompositionMode() {
      this.$store.dispatch('project/setSceneViewCompositionMode', true);
    },
    async saveScene() {
      this.$store.dispatch('project/setSceneViewCompositionMode', false);
      this.$store.dispatch('project/setSavingSceneViewInsight', true);
      this.$emit('addInsightCamera');
      this.$store.dispatch('project/setViewerMode', 'Insights');
    },
    async saveAnnotations() {
      this.$store.dispatch('project/setSavingSceneViewInsight', true);
      await this.$emit('addInsightCamera');
      this.$store.dispatch('project/setViewerMode', 'Insights');
      this.$store.dispatch('project/setSavingSceneViewInsight', false);
    },
    toggleProbeMode() {
      this.setDataProbeStatus(!this.dataProbeStatus);
    },
    goToSplitView() {
      this.$router.push({
        path: this.$route.path,
        query: { secondary: this.configurationId }
      });
    },
    goToSingleView() {
      this.$router.push({
        name: 'ViewerContainer',
        params: { study: this.studyId, configuration: this.configurationId }
      });
    },
    onMLError(args) {
      if (args.scenario_id.toString() === this.configurationId.toString()) {
        if (!this.simulation.errors) {
          this.simulation.errors = [];
        }
        this.simulation.errors.push({'message': args.status_message});
      }
    },
    onMLStatusUpdate(args) {
      if (args.scenario_id.toString() === this.configurationId.toString()) {
        this.setMLStatus(args.status_message);
      }
    },
    onResultsDelivered(args) {
      if (args.scenario_id.toString() === this.configurationId.toString()) {
        if (_.isEmpty(this.simulationResultAssets)) {
          this.newResultsAvailableText = 'Simulation Complete';
        } else {
          this.newResultsAvailableText = 'More results are available';
        }
        this.moreResultsAvailable = true;
      }
    },
    goToResults() {
      this.$router.push(this.resultsRoute);
      this.$router.go(0);
    },
    async setTheme(theme, index) {
      this.selectedTheme = theme;
      this.$emit('setSelectedThemeIndex', index);
    },
    setCameraView(name) {
      this.$emit('setCameraView', name);
    },
    getCameraView(cameraPosition) {
      return cameraPosition?.position.object.type;
    },
    getCameraName(name) {
      const cameraName = `${name.toString()}`.substring(0, 15);
      return cameraName;
    },
    async setCamera(cameraPosition) {
      this.isActive = false;
      await this.selectCameraPosition(cameraPosition);
      this.$emit('setCamera');
    },
    saveCurrentCamera() {
      this.isActive = false;
      this.$emit('addCamera');
    },
    async showCameraMenu(cameraPosition) {
      await this.selectCameraPosition(cameraPosition);
      this.isActive = true;
      this.cameraToUpdate = cameraPosition;
    },
    renameCameraPosition() {
      this.isActive = false;
      this.$emit('renameCamera');
    },
    deleteCameraPosition() {
      this.isActive = false;
      this.$emit('deleteCamera');
    },
    resetCamera() {
      this.$emit('resetCamera');
    },
    toggleScreenShotMode() {
      this.$emit('toggleScreenShotMode');
    },
    toggleClippingPlaneMode() {
      this.$emit('toggleClippingPlaneMode');
    },
    toggleDrawingMode() {
      if(!this.drawingMode) {
        this.setDataPanelOpenStore(false);
        this.resizeViewerCanvas(true);
      }
      this.setDrawingMode(!this.drawingMode);
    },
    setClippingPlaneHeight(height) {
      this.$emit('setClippingPlaneHeight', height);
    },
    editSimulation() {
      this.configurationIdToEdit = Number(this.$route.params.configuration);
      this.scenarioNameToEdit = this.selectedSimulationLabel;
      this.simulationIdToEdit = this.simulation.id;
      this.openEditModal = !this.openEditModal;
    },
    submitScenario() {
      this.$store.dispatch('project/setScenarioConfirmationModalVisibility', true);
    },
    async resubmitMlScenario() {
      this.resubmitPending = true;
      await this.$store.dispatch('project/resubmitMlScenario', {
        projectId: Number(this.$route.params.id),
        studyId: Number(this.$route.params.study),
        configurationId: Number(this.$route.params.configuration),
        simulationId: this.simulation.id
      });
      this.$router.go(0);
    },
    showDeleteModal() {
      this.$store.dispatch('project/setDeleteScenarioModalVisibility', true);
    },
    ...mapActions({
      clearSelectedLayerSets: 'project/simulationAsset/clearSelectedLayerSets',
      selectCameraPosition: 'project/viewer/selectCameraPosition',
      saveLayerState: 'project/viewer/saveLayerState',
      setInboundScenario: 'project/setInboundScenario',
      setScenarioModalVisibility: 'project/setScenarioModalVisibility',
      getLatestMLAPISubmissionStatus: 'project/viewer/getLatestMLAPISubmissionStatus',
      setMLStatus: 'project/viewer/setMLStatus',
      setDrawingMode: 'project/setDrawingMode',
      resizeViewerCanvas: 'project/viewer/setWindowResizeRequired',
      setDataProbeStatus: 'project/viewer/setDataProbeStatus',
      setDataPanelOpenStore: 'project/setDataPanelOpen'
    }),
  },
  watch: {
    annotationsPanelVisible(newValue) {
      if (newValue == true) {
        this.$store.dispatch('project/getInsightPinTypeTemplates');
      }
    },
    currentInsightIndex(newValue) {
      if (newValue != null && newValue != undefined) {
        this.annotationsPanelVisible = false;
        if (this.clippingPlaneMode) {
          this.toggleClippingPlaneMode();
        }
      }
    },
    showProbeMode(newValue) {
      //if probe mode is disabled (no vtk for the selected layer) then turn probe mode off
      if(newValue == false) {
        this.setDataProbeStatus(newValue);
      }
    },
    easterEggThemeIsNotActive(newValue) {
      if (newValue == false) {
        this.selectedTheme = this.easterEggThemeName;
      }
    },
    //this watch is needed to sync the initial value of the scenario.
    selectedThemeIndex(newValue) {
      this.selectedTheme = this.themeLabels[newValue];
    },
    async allIdsLoaded(newValue, oldValue) {
      if (newValue && !oldValue) {
        await this.getLatestMLAPISubmissionStatus({'projectId': this.projectId, 'studyId': this.studyId, 'configurationId': this.selectedConfigurationId, 'simulationId': this.simulationId});
      }
    },
    simulation(newValue) {
      if (newValue) {
        //when the selected simulation changes, reset all user notifications from websockets
        this.setMLStatus('');
        this.newResultsAvailableText = '';
        this.moreResultsAvailable = false;

        //if drawing mode is on, turn it off
        if (this.drawingMode && this.viewerMode == 'Viewer') {
          this.toggleDrawingMode();
        }
      }
    },
    viewerMode(newValue) {
      if (newValue == 'Insights' && this.dataProbeStatus) {
        this.toggleProbeMode();
      }
      this.$store.dispatch('project/setAnnotationsCompositionMode', false);
    }
  }
};
</script>
<style>
.tooltip-inner {
  margin-top: 0rem;
}

.square-btn {
  border-radius: 0rem;
  background-color: var(--grey-400);
  border: 0rem;
  color: black;
}

.split-dropdown {
  max-height: 39.6px;
}

.split-dropdown .btn-secondary {
  display: unset !important;
}
</style>
<style scoped>
.scene-composition {
  color: #007bff;
}

.scene-composition {
  color: #007bff;
  left: 76px;
  position: relative;
}

.no-wrap {
  white-space: nowrap;
  flex-wrap: nowrap;
}

.on-top {
  z-index: 3;
}

.toolbar-text-button {
  min-width: 5rem;
}


.toolbar-icon-button {
  background-color: rgb(255, 255, 255);
}

.toolbar-icon-button:hover {
  background-color: #007bff;
}

.dropdown-button {
  width: calc(100% - 3rem);
  max-height: 32px;
}

.camera-settings {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 20em;
  max-width: 16em;
}

.btn-toolbar, .btn-container {
  height: 100%;
}

.menu {
  all: unset;
  margin-top: 0.25em;
  position: relative;
  width: 1.1em;
  height: 1.1em;
  background-color: var(--grey-200);
  color: var(--grey-600);
  text-align: center;
  line-height: 1.1em;
  font-weight: 700;
  cursor: pointer;
  border-radius: 50%;
  left: 100%;
}

.menu .hover-menu {
  visibility: hidden;
  position: absolute;
  bottom: 0;
  right: 0.313rem;
  width: 3.125rem;
  align-items: baseline;
  background: var(--navy) 0% 0% no-repeat padding-box;
  background: #00395e 0% 0% no-repeat padding-box;
  border: 0.063rem solid #e2e6e9;
  border-radius: 0.313rem;
  opacity: 1;
}

.menu .hover-menu.active {
  visibility: visible;
}

.hover-menu button {
  background: none;
  border: none;
  padding: 0.313rem;
  color: var(--white);
  text-align: left;
  font: normal normal normal 0.625rem/0.75rem Inter;
  letter-spacing: 0rem;
  color: #ffffff;
  opacity: 1;
}

.camera-edit-container {
  position: sticky;
  display: inline-flex;
  margin-left: 10em;
  right: 25%;
}

.dd {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: var(--grey-900);
}

h3 {
  font-size: 0.925rem;
  color: var(--grey-900);
  font-weight: 660;
}

.camera-toggle {
  background-color: var(--grey-400);
}

.clipping-planes-input {
  border: none;
}

.clipping-planes-number {
  height: unset;
  width: 3.75rem;
  border: none;
  border-left: 0.063rem solid #ced4da;
  border-top-right-radius: 0.25em !important;
  border-bottom-right-radius: 0.25em !important;
}

.clipping-planes-slider {
  width: 12.5rem;
  border: none;
}

.clipping-planes-x {
  cursor: pointer;
}
.loading-spinner {
  all: unset;
  width: 1.1em;
  height: 1.1em;
  line-height: 1.5em;
  font-weight: 700;
  cursor: pointer;
  padding: 0 1.1em!important;
}

.ctrl-button-container {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}

.create-insight-description-text {
  font-size: 14px;
  font-style: italic;
}
</style>